body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.counter {
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  max-width: 80vw;
}

.para {
  text-align: justify;
  max-width: 95%;
}
.wrapper > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}
@keyframes fadeInPulse {
  0% {
    opacity: 0;
    transform: scale(1);
    color: black;
  }
  50% {
    opacity: 1;
    transform: scale(1.1);
    color: red;
  }
  100% {
    opacity: 1;
    transform: scale(1);
    color: black;
  }
}

.number {
  animation: fadeInPulse 1s ease-in-out;
}

.numbers {
  font-size: 90px;
  height: 100px;
  animation: fadeInPulse 1s ease-in-out;
}

h1 {
  text-align: center;
  margin-top: 35px;
}
h2 {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  margin: 5px;
  text-align: center;
  white-space: nowrap;
}
.sample-text {
  background-color: #4b5320;
  padding: 10px;
  margin: 15px 0;
  width: 100%;
  max-width: 800px;
  text-align: center;
  border-radius: 5px;
  color: white;

  font-size: 20px;
}

a {
  color: #007bff; /* Default link color */
  text-decoration: none; /* Remove underline */
  transition: color 0.3s ease, text-decoration-color 0.3s ease; /* Smooth transition for color and decoration */
}

.sample-texts {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 800px;
  margin: auto;
  padding-bottom: 20px;
}

.sample-texts .para:nth-of-type(7),
.para:nth-of-type(8) {
  margin-top: 10px;
}

.ho {
  display: inline;
  white-space: nowrap;
}

.ho::before {
  content: "приблизно, але достатньо точно"; /* Content for the hover text */
  display: block; /* Ensure it's a block element for proper positioning */
  position: absolute; /* Absolute positioning for the hover text */
  background-color: rgba(0, 0, 0, 0.8); /* Background color of the hover text */
  color: white; /* Font color */
  padding: 10px; /* Padding around the text */
  border-radius: 5px; /* Rounded corners */
  font-size: 0.6em; /* Font size */
  width: auto; /* Auto width */
  white-space: nowrap; /* Prevent text wrapping */
  left: 50%; /* Position it to the left */
  transform: translateX(-50%); /* Center the text horizontally */
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s ease; /* Smooth transition for opacity */
}

.ho:hover::before {
  opacity: 1; /* Show the overlay on hover */
}

@keyframes firework {
  0% {
    transform: translate(var(--x), var(--initialY));
    width: var(--initialSize);
    opacity: 1;
  }
  50% {
    width: 0.5vmin;
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    width: var(--finalSize);
    opacity: 0;
    overflow: hidden;
  }
}

@keyframes firework2 {
  0% {
    transform: translate(var(--x), var(--initialY));
    width: var(--initialSize);
    opacity: 1;
  }
  50% {
    width: 0.5vmin;
    opacity: 1;
  }
  100% {
    width: var(--finalSize);
    opacity: 0;
  }
}

.firework,
.firework::before,
.firework::after,
.firework2,
.firework2::before,
.firework2::after {
  --initialSize: 5vmin;
  --finalSize: 45vmin;
  --particleSize: 0.5vmin;
  --color1: white;
  --color2: khaki;
  --color3: yellow;
  --color4: lime;
  --color5: gold;
  --color6: mediumseagreen;
  --y: -30vmin;
  --x: -50%;
  --initialY: 10vmin;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, var(--y));
  width: var(--initialSize);
  aspect-ratio: 1;
  background: radial-gradient(
        circle,
        var(--color1) var(--particleSize),
        #0000 0
      )
      0% 0%,
    radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 100% 0%,
    radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 100%
      100%,
    radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 0% 100%,
    radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 50% 0%,
    radial-gradient(circle, var(--color2) var(--particleSize), #0000 0) 100% 50%,
    radial-gradient(circle, var(--color3) var(--particleSize), #0000 0) 50% 100%,
    radial-gradient(circle, var(--color4) var(--particleSize), #0000 0) 0% 50%,
    /* bottom right */
      radial-gradient(circle, var(--color5) var(--particleSize), #0000 0) 80%
      90%,
    radial-gradient(circle, var(--color6) var(--particleSize), #0000 0) 95% 90%,
    radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 90% 70%,
    radial-gradient(circle, var(--color2) var(--particleSize), #0000 0) 100% 60%,
    radial-gradient(circle, var(--color3) var(--particleSize), #0000 0) 55% 80%,
    radial-gradient(circle, var(--color4) var(--particleSize), #0000 0) 70% 77%,
    /* bottom left */
      radial-gradient(circle, var(--color5) var(--particleSize), #0000 0) 22%
      90%,
    radial-gradient(circle, var(--color6) var(--particleSize), #0000 0) 45% 90%,
    radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 33% 70%,
    radial-gradient(circle, var(--color2) var(--particleSize), #0000 0) 10% 60%,
    radial-gradient(circle, var(--color3) var(--particleSize), #0000 0) 31% 80%,
    radial-gradient(circle, var(--color4) var(--particleSize), #0000 0) 28% 77%,
    radial-gradient(circle, var(--color5) var(--particleSize), #0000 0) 13% 72%,
    /* top left */
      radial-gradient(circle, var(--color6) var(--particleSize), #0000 0) 80%
      10%,
    radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 95% 14%,
    radial-gradient(circle, var(--color2) var(--particleSize), #0000 0) 90% 23%,
    radial-gradient(circle, var(--color3) var(--particleSize), #0000 0) 100% 43%,
    radial-gradient(circle, var(--color4) var(--particleSize), #0000 0) 85% 27%,
    radial-gradient(circle, var(--color5) var(--particleSize), #0000 0) 77% 37%,
    radial-gradient(circle, var(--color6) var(--particleSize), #0000 0) 60% 7%,
    /* top right */
      radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 22%
      14%,
    radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 45% 20%,
    radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 33% 34%,
    radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 10% 29%,
    radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 31% 37%,
    radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 28% 7%,
    radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 13% 42%;
  background-size: var(--initialSize) var(--initialSize);
  background-repeat: no-repeat;
}

.firework {
  animation: firework 4s;
  animation-iteration-count: 2;
}

.firework2 {
  animation: firework2 4s;
  animation-iteration-count: 2;
}

.firework::before,
.firework2::before {
  --x: -50%;
  --y: -50%;
  --initialY: 50%;
  transform: translate(-50%, -50%) rotate(40deg) scale(1) rotateY(40deg);
}

.firework::after,
.firework2::after {
  --x: -50%;
  --y: -50%;
  --initialY: -50%;
  transform: translate(-50%, -50%) rotate(170deg) scale(1) rotateY(-30deg);
}

.firework:nth-child(2),
.firework2:nth-child(2) {
  --x: 30vmin;
}

.firework:nth-child(2),
.firework:nth-child(2)::before,
.firework:nth-child(2)::after,
.firework2:nth-child(2),
.firework2:nth-child(2)::before,
.firework2:nth-child(2)::after {
  --color1: white;
  --color2: violet;
  --color3: fuchsia;
  --color4: orchid;
  --color5: plum;
  --color6: lavender;
  --color7: pink;
  --finalSize: 70vmin;
  left: 30%;
  top: 60%;
  animation-delay: -0.1s;
}

.firework:nth-child(3),
.firework2:nth-child(3) {
  --x: -30vmin;
  --y: -50vmin;
}

.firework:nth-child(3),
.firework:nth-child(3)::before,
.firework:nth-child(3)::after,
.firework2:nth-child(3),
.firework2:nth-child(3)::before,
.firework2:nth-child(3)::after {
  --color1: white;
  --color2: cyan;
  --color3: lightblue;
  --color4: PaleTurquoise;
  --color5: SkyBlue;
  --color6: lavender;
  --finalSize: 65vmin;
  left: 70%;
  top: 60%;
  animation-delay: -0.1s;
}
